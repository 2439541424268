import React from "react";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import SVG from "react-inlinesvg";

import { Heading3 } from "../heading";
import Mono, { MonoBold, MonoUppercase } from "../mono";
import RatioBox from "../ratio-box";

import Logo from "src/images/huiput-final.svg";

const StyledCard = styled.div`
  position: relative;
  z-index: -1;
  overflow: hidden;
`;

const StyledBackground = styled.div`
  position: relative;
  height: inherit;
  user-select: none;
  overflow: hidden;
  border-bottom: 2px solid var(--color-black);
  background-color: var(--color-pink);
  color: #fff;

  svg {
    position: absolute;
    width: 40%;
    max-width: 178px;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
`;

const StyledContent = styled.div`
  text-align: center;
  padding: 0;
  margin: 0 auto;
  color: var(--color-black);
  padding: 10px 10px;
`;

const StyledHeading = styled(Heading3)`
  color: var(--color-black);
`;

class TbaCard extends React.Component {
  render() {
    return (
      <StyledCard>
        <RatioBox ratio="881/512">
          <StyledBackground>
            <SVG src={Logo} />
          </StyledBackground>
        </RatioBox>
        <StyledContent>
          <StyledHeading as="h3">TBA</StyledHeading>
        </StyledContent>
      </StyledCard>
    );
  }
}

export default TbaCard;
